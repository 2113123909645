/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

import { ApparatusModel, FileUploads } from "./ApparatusAdapter";
import { InspectionStateType } from "./InspectionAdapter";

export interface TestplanResultModel extends Model {
    name: string;
}

export interface TestplanModel extends Model {
    uuid: string;
    apparatus_id: number;
    user_id: number;
    inspection_plan: string;
    calibration_procedure: string;
    uploads?: FileUploads[];
    apparatus?: ApparatusModel;
}

export interface TestplanModelPaginator extends Paginator {
    testplans: TestplanModel[];
}

interface TestplanPostForm {
    success: boolean;
    testplan?: TestplanModel;
    errors?: string[];
}

export interface TestplanAddModel extends Model {
    apparatus_id: number;
    inspection_plan: string;
    calibration_procedure?: string;
    index?: number;
}

export interface TestplanUpload {
    testplanId?: number;
    apparatus_id?: number;
    inspection_plan: string;
    index?: number;
    state: InspectionStateType;
    files?: File[];
    onProgress?: (progress: number) => void;
}

export default class TestplanAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    TestplanModel,
    TestplanModelPaginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("testplans", "testplan", "testplans", adapter);
    }

    public async view(id: number): Promise<TestplanModel> {
        const { data } = await this._get<{ testplan: TestplanModel }>(`view/${id}`);

        return data.testplan;
    }

    public async index(search = ""): Promise<TestplanModelPaginator> {
        const { data } = await this._get<TestplanModelPaginator>(`index?search=${search}`);

        return data;
    }

    public async addTestplan(
        addData: TestplanAddModel,
        images: File[] | undefined,
        onProgress?: (progress: number) => void
    ): Promise<TestplanPostForm> {
        const formData = new FormData();

        Object.keys(addData).map((key) => {
            const keyType = key as keyof TestplanAddModel;

            formData.append(key, String(addData[keyType]));
        });

        if (images) {
            images.map((img) => {
                formData.append("uploads[file][]", img);
            });
        }
        const { data } = await this._post<TestplanPostForm>(`add`, formData, {
            config: {
                onUploadProgress: (progress: ProgressEvent) => {
                    const percentCompleted = Math.round((progress.loaded * 100) / progress.total);
                    onProgress && onProgress(percentCompleted);
                },
            },
        });

        return data;
    }

    public async editTestplan(
        id: number,
        editData: TestplanAddModel,
        images: File[] | undefined
    ): Promise<TestplanPostForm> {
        const formData = new FormData();

        Object.keys(editData).map((key) => {
            const keyType = key as keyof TestplanAddModel;

            formData.append(key, String(editData[keyType]));
        });

        if (images) {
            images.map((img) => {
                formData.append("uploads[file][]", img);
            });
        }
        const { data } = await this._post<TestplanPostForm>(`edit/${id}`, formData);

        return data;
    }

    public async deleteTestplan(id: number): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(`deleteTestplanSameUser/${id}`);

        return data.success;
    }
}
