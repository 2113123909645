/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

type PaginatorInner = {
    page: number;
    pageCount: number;
    count: number;
    perPage: number;
    sort: string;
    direction: "asc" | "desc";
    nextPage: boolean;
};

interface LogsData {
    key: string;
    old: unknown;
    new: unknown;
}

interface DataLogs {
    label: string;
    value: number;
}

interface LogStatistic {
    values: DataLogs[];
    isEmpty: boolean;
}

export interface ApparatusLogsModel extends Model {
    user_id?: number;
    api: boolean;
    creator_name: string;
    user_name_original: string;
    apparatus_id: number;
    user_img?: string;
    info: string;
    data?: LogsData[];
    model?: string;
    dependent_id?: number;
}

interface ApparatusLogsData {
    apparatusesLogs: ApparatusLogsModel[];
    lastLogId: number;
    paginator: PaginatorInner;
}

export default class ApparatusLogsAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("ApparatusesLogs", "ApparatusesLog", "ApparatusesLogs", adapter);
    }

    public async getLogIndex(id: string, page = 0): Promise<ApparatusLogsData> {
        const { data } = await this._get<ApparatusLogsData>(`getLogIndex/${id}?page=${page}`);

        return data;
    }

    public async getMonthLogStatistic(id: string): Promise<LogStatistic> {
        const { data } = await this._get<LogStatistic>(`getMonthLogStatistic/${id}`);

        return data;
    }
}
