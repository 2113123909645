/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { DefaultPaginationRequestData } from "@components/Table/TableBuilder";
import { fnPaginationParams, PaginatorParams } from "@hook/usePaginationParams";
import { DateTime } from "luxon";

import { ApparatusModel, FileUploads } from "./ApparatusAdapter";
import { InspectionResultModel } from "./InspectionResultAdapter";

export enum InspectionType {
    OTHER = "other",
    CALIBRATION = "calibration",
    REPAIR = "repair",
    INSPECTION = "inspection",
    DECISION = "decision",
}

export interface InspectionModel extends Model {
    report: string;
    type: InspectionType;
    apparatus_id: number;
    inspection_result_id: number;
    checked_at: DateTime;
    inspection_result: InspectionResultModel;
    apparatus: ApparatusModel;
    uploads?: FileUploads[];
}

export enum InspectionStateType {
    NONE,
    IDLE,
    UPLOAD,
    FAILED,
    FINISHED,
}

export interface InspectionSingleUpload {
    uInnspectionId?: string;
    type: InspectionType;
    apparatus_id?: number;
    inspection_result_id: number;
    checked_at: DateTime;
    files?: File[];
    state: InspectionStateType;
    onProgress?: (progress: number) => void;
    inspectionId?: number;
}

export interface InspectionModelPaginator extends Paginator {
    inspections: InspectionModel[];
}

interface InspectionPostForm {
    success: boolean;
    inspection?: InspectionModel;
    errors?: string[];
}

export interface InspectionAddModel extends Model {
    id?: number;
    type: InspectionType;
    apparatus_id: number;
    report: string;
    checked_at: DateTime;
    inspection_result_id: number;
    index?: number;
}

export default class InspectionAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    InspectionModel,
    InspectionModelPaginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("inspections", "inspection", "inspections", adapter);
    }

    public async view(id: number): Promise<InspectionModel> {
        const { data } = await this._get<{ inspection: InspectionModel }>(`view/${id}`);

        return data.inspection;
    }

    public async index(
        paginator?: PaginatorParams
    ): Promise<DefaultPaginationRequestData<InspectionModel>> {
        const { params } = fnPaginationParams(paginator);
        const { data } = await this._get<DefaultPaginationRequestData<InspectionModel>>(
            `index${params}`
        );

        return data;
    }

    public async addInspection(
        addData: InspectionAddModel,
        images: File[] | undefined,
        onProgress?: (progress: number) => void
    ): Promise<InspectionPostForm> {
        const formData = new FormData();

        Object.keys(addData).map((key) => {
            const keyType = key as keyof InspectionAddModel;

            formData.append(key, String(addData[keyType]));
        });

        if (images) {
            images.map((img) => {
                formData.append("uploads[file][]", img);
            });
        }
        const { data } = await this._post<InspectionPostForm>(`add`, formData, {
            config: {
                onUploadProgress: (progress: ProgressEvent) => {
                    const percentCompleted = Math.round((progress.loaded * 100) / progress.total);
                    onProgress && onProgress(percentCompleted);
                },
            },
        });

        return data;
    }

    public async editInspection(
        id: number,
        editData: InspectionAddModel,
        images: File[] | undefined
    ): Promise<InspectionPostForm> {
        const formData = new FormData();

        Object.keys(editData).map((key) => {
            const keyType = key as keyof InspectionAddModel;

            formData.append(key, String(editData[keyType]));
        });

        if (images) {
            images.map((img) => {
                formData.append("uploads[file][]", img);
            });
        }

        const { data } = await this._post<InspectionPostForm>(`edit/${id}`, formData);

        return data;
    }

    public async deleteInspection(id: number): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(`deleteInspection/${id}`);

        return data.success;
    }
}
