/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

import { DeafultResponseError } from "./ApiAdapter";

export interface IndexType {
    id?: number;
    name: string;
}

type PaginatorInner = {
    page: number;
    pageCount: number;
    count: number;
    perPage: number;
    sort: string;
    direction: "asc" | "desc";
    nextPage: boolean;
};
export interface BasedataAdd extends Model {
    manufactor_id?: number;
    manufactor?: string;
    model: string;
    basedata_type: number[];
    description: string;
    basedata_type_new?: string[];
}

export interface Manufacturer extends Model {
    name: string;
}

export interface BasedataType extends Model {
    name: string;
}

export interface UploadImg {
    id: string;
    foreign_key: number | string;
    url: string;
}

export interface Basedata extends Model {
    uuid: string;
    manufactor_id?: number;
    model: string;
    name: string;
    description: string;
    apparatus_basedata_types?: BasedataType[];
    manufacturer?: Manufacturer;
    img?: UploadImg[];
}

export interface BasedataAddReturn extends Model {
    success: boolean;
    apparatusesBasedata?: Basedata;
    errors?: DeafultResponseError;
}

export interface ApparatusModelPaginator extends Paginator {
    apparatusesBasedata: Model[];
}

interface BasedataListData {
    apparatusBasedatas: Basedata[];
    paginator: PaginatorInner;
}

export default class ApparatusBasedataAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("apparatusBasedatas", "apparatusBasedata", "apparatusBasedatas", adapter);
    }

    public async addBasedata(
        addData: BasedataAdd,
        images: File[] | null
    ): Promise<BasedataAddReturn> {
        const formData = new FormData();

        Object.keys(addData).map((key) => {
            const keyType = key as keyof BasedataAdd;

            if (Array.isArray(addData[keyType])) {
                const toMap: string[] = addData[keyType] as string[];
                toMap.map((value) => {
                    formData.append(key + "[]", String(value));
                });
            } else {
                formData.append(key, String(addData[keyType]));
            }
        });

        if (images) {
            images.map((img) => {
                formData.append("uploads[file][]", img);
            });
        }
        const { data } = await this._post<BasedataAddReturn>(`add`, formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        return data;
    }

    public async getManufactors(): Promise<IndexType[]> {
        const { data } = await this._get<{ manufacturers: IndexType[] }>(`manufactorIndex`);

        return data.manufacturers;
    }

    public async getTypes(): Promise<IndexType[]> {
        const { data } = await this._get<{ types: IndexType[] }>(`typesIndex`);

        return data.types;
    }

    public async getIndexList(search = "", page = 0): Promise<BasedataListData> {
        const { data } = await this._get<BasedataListData>(
            `getIndex?search=${search}&page=${page}`
        );

        return data;
    }

    public async getView(id: number): Promise<Basedata> {
        const { data } = await this._get<{ apparatusBasedata: Basedata }>(`getView/${id}`);

        return data.apparatusBasedata;
    }
}
